import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container } from '@mui/material';
import Home from './Home';
import Contact from './Contact';
import About from './About';
import Stories from './Stories';
import Footer from './Footer'; // You can create a separate Footer component
import './App.css'; // Importing a CSS file


const storiesData = []; // You can define your stories data here

const App = () => {
  return (
    <Router>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Tea With GPT
          </Typography>
          <nav>
          <NavLink component={Link} to="/" exact activeClassName="active">Home</NavLink>
          <NavLink component={Link} to="/stories" activeClassName="active">Short Stories</NavLink>
          <NavLink component={Link} to="/contact" activeClassName="active">Contact</NavLink>
          <NavLink component={Link} to="/about" activeClassName="active">About</NavLink>

          </nav>
        </Toolbar>
      </AppBar>
      <main>
        <Container>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/stories/*" element={<Stories stories={storiesData} />} />
            {/* Add more routes as needed */}
          </Routes>
        </Container>
      </main>
      <Footer>
        <Typography variant="body2">&copy; 2023 Tea With GPT. Bringing AI to the table.</Typography>
      </Footer>
    </Router>
  );
};

export default App;
