import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import StoryDetail from './StoryDetail';
import chroniclesPdf from './cotc.pdf';
//import chapter1Pdf from './Chapter1.pdf';

export const storiesData = [
  { title: 'Chronicles of the Celestial', author: 'Author 1', pdfFile: chroniclesPdf },
  //{ title: 'Chapter 1', author: 'Author 2', pdfFile: chapter1Pdf }
];

function Stories() {
  return (
    <div>
      <h2>Short Stories</h2>
      <Routes>
        <Route path="/" element={<StoryList />} />
        <Route path=":storyIndex" element={<StoryDetail />} />
      </Routes>
    </div>
  );
}

function StoryList() {
  return (
    <ul>
      {storiesData.map((story, index) => (
        <li key={index}>
          <Link to={`${index}`}>{story.title}</Link>
        </li>
      ))}
    </ul>
  );
}

export default Stories;