import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PDFReader from './PDFReader';
import { storiesData } from './Stories';

function StoryDetail() {
  const { storyIndex } = useParams();
  const story = storiesData[storyIndex];
  const navigate = useNavigate();

  return (
    <div>
      <button onClick={() => navigate(-1)}>Back to Story List</button>
      <h2>{story.title}</h2>
      <p>By {story.author}</p>
      <PDFReader file={story.pdfFile} />
    </div>
  );
}

export default StoryDetail;
