import React from 'react';
import { Typography, Container } from '@mui/material';

function Footer() {
  return (
    <footer>
      <Container>
        <Typography variant="body2" align="center">
          &copy; 2023 Tea With GPT. Bringing AI to the table.
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
